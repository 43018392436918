import { Fraction, Percent, Token } from '@dolomite-exchange/sdk-core'
import { ChainId, LINK, ONE_FRACTION, UNI, USDC, USDT, WBTC, WE_ETH, WETH, WST_ETH } from '../../constants'
import { METH } from '../../constants/tokens/METH'
import { GRAI } from '../../constants/tokens/GRAI'
import { PT_METH_DEC_2024_SPECIAL_ASSET } from '../../constants/isolation/assets/PT_METH_DEC_2024_SPECIAL_ASSET'
import { BorrowPosition } from '../../types/borrowPositionData'
import { useChainIdToTokenToFiatPriceMap, useFiatPricesWithLoadingIndicator } from '../../hooks/useFiatValue'
import { tryParseAmount } from '../../state/trade/hooks'
import { CurrencyAmount } from '@dolomite-exchange/v2-sdk'
import { getStrategyInterestRate } from '../../hooks/useNetInterestRate'
import { useMemo } from 'react'
import { formatAmount } from '../../utils/formatAmount'
import { RefreshFrequency } from '../../state/chain/hooks'
import { InterestRate, useChainIdToInterestRateMap } from '../../types/interestRateData'
import { PT_USDE_DEC_2024_SPECIAL_ASSET } from '../../constants/isolation/assets/PT_USDE_DEC_2024_SPECIAL_ASSET'
import { GM_ETH_SPECIAL_ASSET } from '../../constants/isolation/assets/GM_ETH_SPECIAL_ASSET'
import { GMX } from '../../constants/tokens/GMX'
import { GMX_SPECIAL_ASSET } from '../../constants/isolation/assets/GMX_SPECIAL_ASSET'
import { BRIDGED_USDC } from '../../constants/tokens/USDC'
import { GM_BTC_SPECIAL_ASSET } from '../../constants/isolation/assets/GM_BTC_SPECIAL_ASSET'
import { GM_UNI_USD_SPECIAL_ASSET } from '../../constants/isolation/assets/GM_UNI_USD_SPECIAL_ASSET'
import { GM_LINK_USD_SPECIAL_ASSET } from '../../constants/isolation/assets/GM_LINK_USD_SPECIAL_ASSET'
import { PT_CM_ETH_FEB_2025_SPECIAL_ASSET } from '../../constants/isolation/assets/PT_CM_ETH_FEB_2025_SPECIAL_ASSET'
import { CM_ETH } from '../../constants/tokens/CM_ETH'
import { GM_GMX_SPECIAL_ASSET } from '../../constants/isolation/assets/GM_GMX_SPECIAL_ASSET'
import { PENDLE } from '../../constants/tokens/PENDLE'
import { GM_PENDLE_USD_SPECIAL_ASSET } from '../../constants/isolation/assets/GM_PENDLE_USD_SPECIAL_ASSET'
import { GLV_ETH_USD_SPECIAL_ASSET } from '../../constants/isolation/assets/GLV_ETH_USD_SPECIAL_ASSET'
import { GLV_BTC_USD_SPECIAL_ASSET } from '../../constants/isolation/assets/GLV_BTC_USD_SPECIAL_ASSET'
import { SUSDE } from '../../constants/tokens/SUSDE'
import { HONEY } from '../../constants/tokens/HONEY'
import { SR_USD } from '../../constants/tokens/SR_USD'
import { BERA_ETH } from '../../constants/tokens/BERA_ETH'
import { USDE } from '../../constants/tokens/USDE'
import { WE_ETH_SPECIAL_ASSET } from '../../constants/isolation/assets/WE_ETH_SPECIAL_ASSET'

const PT_METH = PT_METH_DEC_2024_SPECIAL_ASSET().isolationModeInfo?.getWrappedToken(ChainId.MANTLE)
const PT_USDE = PT_USDE_DEC_2024_SPECIAL_ASSET().isolationModeInfo?.getWrappedToken(ChainId.MANTLE)
const PT_CMETH = PT_CM_ETH_FEB_2025_SPECIAL_ASSET().isolationModeInfo?.getWrappedToken(ChainId.MANTLE)
const GM_ETH = GM_ETH_SPECIAL_ASSET().isolationModeInfo?.getWrappedToken(ChainId.ARBITRUM_ONE)
const GM_BTC = GM_BTC_SPECIAL_ASSET().isolationModeInfo?.getWrappedToken(ChainId.ARBITRUM_ONE)
const GM_UNI_USD = GM_UNI_USD_SPECIAL_ASSET().isolationModeInfo?.getWrappedToken(ChainId.ARBITRUM_ONE)
const GM_LINK_USD = GM_LINK_USD_SPECIAL_ASSET().isolationModeInfo?.getWrappedToken(ChainId.ARBITRUM_ONE)
const S_GMX = GMX_SPECIAL_ASSET().isolationModeInfo?.getWrappedToken(ChainId.ARBITRUM_ONE)
const GM_GMX = GM_GMX_SPECIAL_ASSET().isolationModeInfo?.getWrappedToken(ChainId.ARBITRUM_ONE)
const GM_PENDLE = GM_PENDLE_USD_SPECIAL_ASSET().isolationModeInfo?.getWrappedToken(ChainId.ARBITRUM_ONE)
const GLV_ETH = GLV_ETH_USD_SPECIAL_ASSET().isolationModeInfo?.getWrappedToken(ChainId.ARBITRUM_ONE)
const GLV_BTC = GLV_BTC_USD_SPECIAL_ASSET().isolationModeInfo?.getWrappedToken(ChainId.ARBITRUM_ONE)
//const SUSDE = SUSDE//SUSDE_SPECIAL_ASSET().isolationModeInfo?.getWrappedToken(ChainId.BERACHAIN)

export const STRATEGY_INDEX_DEPRECATED_LOWER = 100_000
export const STRATEGY_INDEX_DEPRECATED_UPPER = 1_000_000
export const STRATEGY_INDEX_LOWER = 1_000_000_000
export const STRATEGY_INDEX_UPPER = 10_000_000_000
export const STRATEGY_POSITION_ID_THRESHOLD = 1_000_000
export const STRATEGY_ID_THRESHOLD = 1_000

export enum Risk {
  low = 'Low',
  medium = 'Med',
  high = 'High',
}

export enum StrategyTagType {
  filter,
  reward,
}

export interface StrategyTag {
  name: string
  color: string
  tooltip: string
  type: StrategyTagType
}

export const Tags: Record<string, StrategyTag> = {
  yieldMax: {
    name: 'Yield Maximizing',
    color: '#7fa225',
    tooltip:
      ' strategies are strategies designed to produce the highest yield, taking advantage of the difference in borrow and supply rates, as well as additional rewards.',
    type: StrategyTagType.filter,
  },
  ethStaking: {
    name: 'ETH Staking',
    color: '#7fa225',
    tooltip: ' strategies utilize the yield earned from staked ETH.',
    type: StrategyTagType.filter,
  },
  deltaNeutral: {
    name: 'Δ Neutral',
    color: '#5d869e',
    tooltip:
      ' or "delta neutral" strategies are ones designed to be resistant to changes in the price of assets. This is done having debt and collateral that are correlated in price.',
    type: StrategyTagType.filter,
  },
  rewardsMax: {
    name: 'Rewards Maximizing',
    color: '#7fa225',
    tooltip:
      ' strategies are strategies designed to maximize the rewards you earn. These rewards can take the form of tokens, points, or other rewards.',
    type: StrategyTagType.filter,
  },
  lrt: {
    name: 'LRT',
    color: '#05a47b',
    tooltip:
      ' strategies utilize "liquid restaking tokens", which are tokens received when users deposit ETH in any number of liquid restaking protocols. They earn ETH staking yield.',
    type: StrategyTagType.filter,
  },
  gmx: {
    name: 'GMX',
    color: '#05a47b',
    tooltip:
      ' strategies utilize GMX pools, which supply the liquidity for the decentralized perpetuals exchange GMX, and earn fees from leveraged trading, borrowing fees, and swaps.',
    type: StrategyTagType.filter,
  },
  lst: {
    name: 'LST',
    color: '#05a47b',
    tooltip:
      ' strategies utilize "liquid staking tokens", which are tokens received when users deposit ETH in any number of liquid staking protocols. They earn ETH staking yield, as well as Actively Validated Services or AVS yield.',
    type: StrategyTagType.filter,
  },
  ethExposure1x: {
    name: '1x Eth Exposure',
    color: '#e59006',
    tooltip:
      ' strategies have 1x exposure to the price of ETH, meaning that the value of your equity in the strategy will increase and decrease at approximately the same rate that the price of ETH increases or decreases.',
    type: StrategyTagType.filter,
  },
  ethExposure2x: {
    name: '2x Eth Exposure',
    color: '#e59006',
    tooltip:
      ' strategies have 2x exposure to the price of ETH, meaning that the value of your equity in the strategy will increase and decrease at approximately twice the rate that the price of ETH increases or decreases.',
    type: StrategyTagType.filter,
  },
  ethExposure3x: {
    name: '3x Eth Exposure',
    color: '#e59006',
    tooltip:
      ' strategies have 3x exposure to the price of ETH, meaning that the value of your equity in the strategy will increase and decrease at approximately three times the rate that the price of ETH increases or decreases.',
    type: StrategyTagType.filter,
  },
  ethExposure4x: {
    name: '4x Eth Exposure',
    color: '#e59006',
    tooltip:
      ' strategies have 4x exposure to the price of ETH, meaning that the value of your equity in the strategy will increase and decrease at approximately four times the rate that the price of ETH increases or decreases.',
    type: StrategyTagType.filter,
  },
  ethBull: {
    name: 'ETH Bull',
    color: '#e59006',
    tooltip:
      ' strategies are for those who believe the value of ETH will rise. These strategies generally have 1x or leveraged exposure to the price of ETH.',
    type: StrategyTagType.filter,
  },
  btcBull: {
    name: 'BTC Bull',
    color: '#e59006',
    tooltip:
      ' strategies are for those who believe the value of BTC will rise. These strategies generally have 1x or leveraged exposure to the price of BTC.',
    type: StrategyTagType.filter,
  },
  leveragedExposure: {
    name: 'Leveraged Exposure',
    color: '#e59006',
    tooltip:
      ' strategies have leveraged exposure to the price of an asset, meaning that the value of your equity in the strategy will increase and decrease at some multiple of the rate that the underlying asset increases or decreases in price.',
    type: StrategyTagType.filter,
  },
  expirable: {
    name: 'Expirable',
    color: '#D33E43',
    tooltip:
      ' strategies are ones that use assets that mature or expire at a certain date, and as such are only viable until that time.',
    type: StrategyTagType.filter,
  },
  leverage8x: {
    name: '8x',
    color: '#8fc942',
    tooltip:
      ' strategies are looped so that when active, their collateral is 8x your deposit amount. This leverage does not necessarily mean any additional price exposure, there are looped strategies that are delta neutral or with 1x price exposure while still increasing yield. However, strategies with higher leverage have a greater amount borrowed relative to collateral, and as a result have a lower health factor.',
    type: StrategyTagType.filter,
  },
  leverage7x: {
    name: '7x',
    color: '#8fc942',
    tooltip:
      ' strategies are looped so that when active, their collateral is 7x your deposit amount. This leverage does not necessarily mean any additional price exposure, there are looped strategies that are delta neutral or with 1x price exposure while still increasing yield. However, strategies with higher leverage have a greater amount borrowed relative to collateral, and as a result have a lower health factor.',
    type: StrategyTagType.filter,
  },
  leverage6x: {
    name: '6x',
    color: '#8fc942',
    tooltip:
      ' strategies are looped so that when active, their collateral is 6x your deposit amount. This leverage does not necessarily mean any additional price exposure, there are looped strategies that are delta neutral or with 1x price exposure while still increasing yield. However, strategies with higher leverage have a greater amount borrowed relative to collateral, and as a result have a lower health factor.',
    type: StrategyTagType.filter,
  },
  leverage5x: {
    name: '5x',
    color: '#8fc942',
    tooltip:
      ' strategies are looped so that when active, their collateral is 5x your deposit amount. This leverage does not necessarily mean any additional price exposure, there are looped strategies that are delta neutral or with 1x price exposure while still increasing yield. However, strategies with higher leverage have a greater amount borrowed relative to collateral, and as a result have a lower health factor.',
    type: StrategyTagType.filter,
  },
  leverage4x: {
    name: '4x',
    color: '#8fc942',
    tooltip:
      ' strategies are looped so that when active, their collateral is 4x your deposit amount. This leverage does not necessarily mean any additional price exposure, there are looped strategies that are delta neutral or with 1x price exposure while still increasing yield. However, strategies with higher leverage have a greater amount borrowed relative to collateral, and as a result have a lower health factor.',
    type: StrategyTagType.filter,
  },
  leverage3x: {
    name: '3x',
    color: '#8fc942',
    tooltip:
      ' strategies are looped so that when active, their collateral is 3x your deposit amount. This leverage does not necessarily mean any additional price exposure, there are looped strategies that are delta neutral or with 1x price exposure while still increasing yield. However, strategies with higher leverage have a greater amount borrowed relative to collateral, and as a result have a lower health factor.',
    type: StrategyTagType.filter,
  },
  leverage2x: {
    name: '2x',
    color: '#8fc942',
    tooltip:
      ' strategies are looped so that when active, their collateral is 2x your deposit amount. This leverage does not necessarily mean any additional price exposure, there are looped strategies that are delta neutral or with 1x price exposure while still increasing yield. However, strategies with higher leverage have a greater amount borrowed relative to collateral, and as a result have a lower health factor.',
    type: StrategyTagType.filter,
  },
  minerals: {
    name: 'Minerals',
    color: '#2b6dc2',
    tooltip: ' strategies earn Dolomite Minerals, a reward token that can be tracked on the Minerals page.',
    type: StrategyTagType.reward,
  },
  powder: {
    name: 'Powder',
    color: '#2b6dc2',
    tooltip: ' strategies earn Mantle Powder points, which can be utilized in the Mantle ecosystem.',
    type: StrategyTagType.reward,
  },
  kelp: {
    name: 'Kelp Miles',
    color: '#2b6dc2',
    tooltip: ' strategies earn Kelp Miles, which can be utilized in the KelpDAO ecosystem.',
    type: StrategyTagType.reward,
  },
  eigenLayer: {
    name: 'EigenLayer Points',
    color: '#2b6dc2',
    tooltip: ' strategies earn EigenLayer points, which can be utilized in the EigenLayer ecosystem.',
    type: StrategyTagType.reward,
  },
  ezPoints: {
    name: 'ezPoints',
    color: '#2b6dc2',
    tooltip: ' strategies earn ezPoints points, which can be utilized in the Renzo ecosystem.',
    type: StrategyTagType.reward,
  },
  arb: {
    name: 'ARB Rewards',
    color: '#2b6dc2',
    tooltip: ' strategies earn ARB rewards.',
    type: StrategyTagType.reward,
  },
  usdm: {
    name: 'USDM APR',
    color: '#2b6dc2',
    tooltip:
      ' strategies earn yield from USDM, a stablecoin that earns interest from US Treasuries held in the USDM Reserves.',
    type: StrategyTagType.reward,
  },
  oArb: {
    name: 'oARB Rewards',
    color: '#2b6dc2',
    tooltip:
      ' strategies earn oARB rewards, which can be claimed and vested into ARB on the Rewards page here on Dolomite.',
    type: StrategyTagType.reward,
  },
  goArb: {
    name: 'goARB Rewards',
    color: '#2b6dc2',
    tooltip: ' strategies earn goARB rewards, which can be claimed and vested into ARB on the Gravita web app.',
    type: StrategyTagType.reward,
  },
  pendle: {
    name: 'Pendle Fixed APR',
    color: '#2b6dc2',
    tooltip:
      ' strategies earn a stable yield from Pendle PT assets. This stable yield is tracked from the point when you begin using the strategy, and locking in a fixed yield rate can be a good way to secure a higher yield and make a strategy more profitable.',
    type: StrategyTagType.reward,
  },
  ascend: {
    name: 'Ascend Points',
    color: '#2b6dc2',
    tooltip: ' strategies earn Gravita Ascend points, which can be utilized in the Gravita ecosystem.',
    type: StrategyTagType.reward,
  },
  sats: {
    name: 'Ethena Sats',
    color: '#2b6dc2',
    tooltip: ' strategies earn Ethena Sats points, which can be utilized in the Ethena ecosystem.',
    type: StrategyTagType.reward,
  },
}

export enum OracleType {
  chainlink,
  chronicle,
  redstone,
  other,
}

export interface StrategyInfo {
  id: string
  title: string
  tags: StrategyTag[]
  collateralAssets: Token[]
  debtAssets: Token[]
  risk: Risk
  riskAssessment: string
  leverage: number
  chain: ChainId
  includeRates?: string[]
  specialTag?: string
  steps?: number
  supplyOraclePath: string[] // TODO - update to support multiple supply and borrow assets
  supplyOracleType: OracleType
  borrowOraclePath: string[] // TODO - update to support multiple supply and borrow assets
  borrowOracleType: OracleType
  description: string
  whyItWorks: string
  risks: string
  disabled?: boolean
}

export interface StrategyWithAmounts {
  id: string
  title: string
  tags: StrategyTag[]
  collateralAssets: Token[]
  debtAssets: Token[]
  risk: Risk
  leverage: number
  chain: ChainId
  supplyAmounts: CurrencyAmount<Token>[]
  borrowAmounts: CurrencyAmount<Token>[]
  includeRates?: string[]
  specialTag?: string
  steps?: number
  supplyOraclePath: string[] // TODO - update to support multiple supply and borrow assets
  supplyOracleType: OracleType
  borrowOraclePath: string[] // TODO - update to support multiple supply and borrow assets
  borrowOracleType: OracleType
  description: string
  whyItWorks: string
  risks: string
  disabled?: boolean
}

const STRATEGIES_DATA: StrategyInfo[] = [
  {
    id: '1',
    title: 'Looped PT-mETH',
    tags: [Tags.leverage3x, Tags.yieldMax, Tags.ethExposure1x, Tags.ethStaking, Tags.pendle, Tags.expirable],
    collateralAssets: [PT_METH ?? WETH[ChainId.MANTLE]],
    debtAssets: [METH[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    risk: Risk.low,
    riskAssessment: `This is a lower risk strategy due to the correlated price of the collateral and the debt. Some risks that do exist are smart contract risk as well as the risk that borrow rates on ETH climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 3,
    chain: ChainId.MANTLE,
    includeRates: ['Pendle Fixed APR', 'ETH Staking APR'],
    supplyOraclePath: ['PT-mETH', 'ETH - Pendle TWAP', 'USD - Chronicle (Market Price)'],
    supplyOracleType: OracleType.chronicle,
    borrowOraclePath: ['Chronicle (Market Price)'],
    borrowOracleType: OracleType.chronicle,
    description: `This strategy utilizes PT-mETH as collateral in order to borrow mETH, and swaps that mETH into more PT-mETH collateral, "looping" PT-mETH. This strategy has 1x exposure to the price of mETH because the debt and collateral assets are correlated in price, so only excess collateral has exposure to price movements. This strategy earns interest in the form of PT-mETH.`,
    whyItWorks: `This strategy works because PT-mETH generally earns more interest than is paid on the mETH debt. Additionally, PT-mETH has a stable yield, so you can lock in a high yield. PT-mETH and mETH are also correlated in price, meaning that the value of the debt and collateral will move in the same direction, mitigating price exposure.`,
    risks: `If the borrow rate of mETH climbs above the yield earned on your PT-mETH, the strategy will be negative yielding. If the rate to borrow climbs far above the yield earned for an extended period, there's a chance of liquidation. Additionally, smart contract risk is always present.`,
  },
  {
    id: '2',
    title: 'Looped PT-mETH',
    tags: [Tags.leverage5x, Tags.yieldMax, Tags.ethExposure1x, Tags.ethStaking, Tags.pendle, Tags.expirable],
    collateralAssets: [PT_METH ?? WETH[ChainId.MANTLE]],
    debtAssets: [METH[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. The risk is limited due to the correlated price of the collateral and the debt, however the strategy utilizes high leverage. Some risks that do exist are smart contract risk as well as the risk that borrow rates on ETH climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 5,
    chain: ChainId.MANTLE,
    includeRates: ['Pendle Fixed APR', 'ETH Staking APR'],
    //specialTag: 'Featured',
    supplyOraclePath: ['PT-mETH', 'ETH - Pendle TWAP', 'USD - Chronicle (Market Price)'],
    supplyOracleType: OracleType.chronicle,
    borrowOraclePath: ['Chronicle (Market Price)'],
    borrowOracleType: OracleType.chronicle,
    description: `This strategy utilizes PT-mETH as collateral in order to borrow mETH, and swaps that mETH into more PT-mETH collateral, "looping" PT-mETH. This strategy has 1x exposure to the price of mETH because the debt and collateral assets are correlated in price, so only excess collateral has exposure to price movements. This strategy earns interest in the form of PT-mETH.`,
    whyItWorks: `This strategy works because PT-mETH generally earns more interest than is paid on the mETH debt. Additionally, PT-mETH has a stable yield, so you can lock in a high yield. PT-mETH and mETH are also correlated in price, meaning that the value of the debt and collateral will move in the same direction, mitigating price exposure.`,
    risks: `If the borrow rate of mETH climbs above the yield earned on your PT-mETH, the strategy will be negative yielding. If the rate to borrow climbs far above the yield earned for an extended period, there's a chance of liquidation. Additionally, smart contract risk is always present.`,
  },
  {
    id: '3',
    title: 'Looped GRAI',
    tags: [Tags.leverage2x, Tags.yieldMax, Tags.rewardsMax, Tags.deltaNeutral, Tags.ascend, Tags.goArb],
    collateralAssets: [GRAI[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [USDC[ChainId.ARBITRUM_ONE]],
    risk: Risk.low,
    riskAssessment: `This is a lower risk strategy due to the correlated price of the debt and collateral. Some risks that do exist are smart contract risk as well as the risk that borrow rates on USDC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 2,
    chain: ChainId.ARBITRUM_ONE,
    includeRates: ['Pendle Fixed APR', 'ETH Staking APR'],
    supplyOraclePath: ['Ramses TWAP'],
    supplyOracleType: OracleType.other,
    borrowOraclePath: ['Chainlink (Market Price)'],
    borrowOracleType: OracleType.chainlink,
    description: `This strategy utilizes GRAI as collateral in order to borrow USDC, and swaps that USDC into more GRAI collateral, "looping" GRAI. This strategy is delta-neutral, as both the collateral and debt are stablecoins. This strategy earns interest in the form of GRAI, as well as GRAI rewards.`,
    whyItWorks: `This strategy works because the yield on GRAI, when including all rewards, is higher than the rate to borrow USDC. GRAI and USDC are both stablecoins, so the strategy doesn't have any intrinsic price exposure.`,
    risks: `While GRAI earns a higher rate of return when including rewards, those rewards are claimed off of Dolomite and don't accumulate in the collateral of this strategy. As a result, the value of the GRAI collateral is likely to increase more slowly than the value of the USDC debt, which could lead to liquidation with enough time. This risk is exacerbated with increased USDC borrow rates. Additionally, risk of depeg and smart contract risk are always present.`,
    disabled: true,
  },
  {
    id: '4',
    title: 'Looped GRAI',
    tags: [Tags.leverage4x, Tags.yieldMax, Tags.rewardsMax, Tags.deltaNeutral, Tags.ascend, Tags.goArb],
    collateralAssets: [GRAI[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [USDC[ChainId.ARBITRUM_ONE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. The correlated price of the strategy's debt and collateral helps to reduce risk, however this strategy uses higher leverage. Some risks that do exist are smart contract risk as well as the risk that borrow rates on USDC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 4,
    chain: ChainId.ARBITRUM_ONE,
    includeRates: ['Pendle Fixed APR', 'ETH Staking APR'],
    supplyOraclePath: ['Ramses TWAP'],
    supplyOracleType: OracleType.other,
    borrowOraclePath: ['Chainlink (Market Price)'],
    borrowOracleType: OracleType.chainlink,
    description: `This strategy utilizes GRAI as collateral in order to borrow USDC, and swaps that USDC into more GRAI collateral, "looping" GRAI. This strategy is delta-neutral, as both the collateral and debt are stablecoins. This strategy earns interest in the form of GRAI, as well as GRAI rewards.`,
    whyItWorks: `This strategy works because the yield on GRAI, when including all rewards, is higher than the rate to borrow USDC. GRAI and USDC are both stablecoins, so the strategy doesn't have any intrinsic price exposure.`,
    risks: `While GRAI earns a higher rate of return when including rewards, those rewards are claimed off of Dolomite and don't accumulate in the collateral of this strategy. As a result, the value of the GRAI collateral is likely to increase more slowly than the value of the USDC debt, which could lead to liquidation with enough time. This risk is exacerbated with increased USDC borrow rates. Additionally, risk of depeg and smart contract risk are always present.`,
    disabled: true,
  },
  {
    id: '5',
    title: 'Leveraged mETH',
    tags: [Tags.leverage2x, Tags.rewardsMax, Tags.ethExposure2x, Tags.ethBull, Tags.lst, Tags.eigenLayer],
    collateralAssets: [METH[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    debtAssets: [USDT[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. This strategy has 2x exposure to the price of ETH, so while increases in price are amplified, so are decreases in price, introducing the possibility of liquidation if the price drops too far.`,
    leverage: 2,
    includeRates: ['ETH Staking APR', 'ETH Staking APY'],
    chain: ChainId.MANTLE,
    supplyOraclePath: ['Chronicle (Market Price)'],
    supplyOracleType: OracleType.chronicle,
    borrowOraclePath: ['Chronicle (Market Price)'],
    borrowOracleType: OracleType.chronicle,
    description: `This strategy utilizes mETH as collateral in order to borrow USDT, and swaps that USDT into more mETH collateral, resulting in leveraged exposure to mETH. This strategy has 2x exposure to the price of mETH. This strategy earns interest in the form of mETH, as well as points.`,
    whyItWorks: `This strategy is designed for users who are bullish on ETH, and specifically mETH. The strategy earns leveraged interest in the form of mETH, earns leveraged points, and has 2x exposure to the price of mETH. As a result, even if the rate to borrow USDT is higher than the yield earned on mETH, the strategy can still be profitable if the price of mETH increases.`,
    risks: `This strategy has 2x exposure to the price of mETH, so your position will gain value twice as fast as the price of mETH increases, but it will also lose value twice as fast if the price of mETH falls. If the price of mETH falls too far, the position will be liquidated. Another source of risk is if the rate to borrow USDT remains high for an extended period, increasing the value of your debt and potentially leading to liquidation. Additionally, smart contract risk is always present.`,
  },
  {
    id: '6',
    title: 'Leveraged mETH',
    tags: [Tags.leverage4x, Tags.rewardsMax, Tags.ethExposure4x, Tags.ethBull, Tags.lst, Tags.eigenLayer],
    collateralAssets: [METH[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    debtAssets: [USDT[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    risk: Risk.high,
    riskAssessment: `This is a high risk strategy. This strategy has 4x exposure to the price of ETH, so while increases in price are amplified, so are decreases in price, introducing the possibility of liquidation if the price drops.`,
    leverage: 4,
    includeRates: ['ETH Staking APR', 'ETH Staking APY'],
    chain: ChainId.MANTLE,
    supplyOraclePath: ['Chronicle (Market Price)'],
    supplyOracleType: OracleType.chronicle,
    borrowOraclePath: ['Chronicle (Market Price)'],
    borrowOracleType: OracleType.chronicle,
    description: `This strategy utilizes mETH as collateral in order to borrow USDT, and swaps that USDT into more mETH collateral, resulting in leveraged exposure to mETH. This strategy has 4x exposure to the price of mETH. This strategy earns interest in the form of mETH, as well as points.`,
    whyItWorks: `This strategy is designed for users who are very bullish on ETH, and specifically mETH. The strategy earns leveraged interest in the form of mETH, earns leveraged points, and has 4x exposure to the price of mETH. As a result, even if the rate to borrow USDT is higher than the yield earned on mETH, the strategy can still be profitable if the price of mETH increases.`,
    risks: `This strategy has 4x exposure to the price of mETH, so your position will gain value 4x as fast as the price of mETH increases, but it will also lose value 4x as fast if the price of mETH falls. If the price of mETH falls too far, the position will be liquidated. Another source of risk is if the rate to borrow USDT remains high for an extended period, increasing the value of your debt and potentially leading to liquidation. Additionally, smart contract risk is always present.`,
  },
  {
    id: '7',
    title: 'Long wstETH / Short UNI',
    tags: [Tags.leverage2x, Tags.yieldMax, Tags.leveragedExposure, Tags.ethBull, Tags.ethStaking, Tags.lst],
    collateralAssets: [WST_ETH[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [UNI[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. In the recent past, UNI has roughly approximated ETH's price, so while looping UNI into ETH isn't a delta-neutral strategy, historically it hasn't had the same comparative volatility to a leveraged ETH position against USD. This strategy utilizes only 2x leverage, reducing - but not eliminating - risk.`,
    leverage: 2,
    includeRates: ['ETH Staking APR', 'ETH Staking APY'],
    chain: ChainId.ARBITRUM_ONE,
    supplyOraclePath: ['Chronicle (Market Price)'],
    supplyOracleType: OracleType.chronicle,
    borrowOraclePath: ['Chronicle (Market Price)'],
    borrowOracleType: OracleType.chronicle,
    description: `This strategy utilizes wstETH as collateral in order to borrow UNI, and swaps that UNI into more wstETH collateral, resulting in leveraged exposure to wstETH. This strategy has 2x exposure to the price of wstETH in terms of UNI, meaning the strategy gains value when the USD price of wstETH rises faster than the USD price of UNI. This strategy earns interest in the form of wstETH.`,
    whyItWorks: `This strategy has two aspects at play. First is that UNI generally has a low borrow interest rate, so the resulting loop into wstETH can be high yielding in terms of ETH if wstETH and UNI maintain their prices. Second is that this strategy gains value when the price of wstETH rises faster than the price of UNI - or put differently, increases in terms of UNI - which is a fit for someone bullish on ETH against UNI.`,
    risks: `This strategy has several risks. Leveraged exposure means that if the price of ETH falls too far compared to UNI, the position will be liquidated. There's also a risk that the interest rate to borrow UNI climbs above the yield on wstETH, resulting in the position being negative yielding. Additionally, smart contract risk is always present.`,
  },
  {
    id: '8',
    title: 'Long wstETH / Short UNI',
    tags: [Tags.leverage3x, Tags.yieldMax, Tags.leveragedExposure, Tags.ethBull, Tags.ethStaking, Tags.lst],
    collateralAssets: [WST_ETH[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [UNI[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.high,
    riskAssessment: `This is a high risk strategy. In the recent past, UNI has roughly approximated ETH's price, so while looping UNI into ETH isn't a delta-neutral strategy, historically it hasn't had the same comparative volatility to a leveraged ETH position against USD. This strategy however utilizes 3x leverage, so moderate drops in the price of ETH compared to UNI could result in a liquidation.`,
    leverage: 3,
    includeRates: ['ETH Staking APR', 'ETH Staking APY'],
    chain: ChainId.ARBITRUM_ONE,
    supplyOraclePath: ['Chronicle (Market Price)'],
    supplyOracleType: OracleType.chronicle,
    borrowOraclePath: ['Chronicle (Market Price)'],
    borrowOracleType: OracleType.chronicle,
    description: `This strategy utilizes wstETH as collateral in order to borrow UNI, and swaps that UNI into more wstETH collateral, resulting in leveraged exposure to wstETH. This strategy has 3x exposure to the price of wstETH in terms of UNI, meaning the strategy gains value when the USD price of wstETH rises faster than the USD price of UNI. This strategy earns interest in the form of wstETH.`,
    whyItWorks: `This strategy has two aspects at play. First is that UNI generally has a low borrow interest rate, so the resulting loop into wstETH can be high yielding in terms of ETH if wstETH and UNI maintain their prices. Second is that this strategy gains value when the price of wstETH rises faster than the price of UNI - or put differently, increases in terms of UNI - which is a fit for someone bullish on ETH against UNI.`,
    risks: `This strategy has several risks. This strategy has a higher leverage, and leveraged exposure means that if the price of ETH falls too far compared to UNI, the position will be liquidated. You will need to keep an eye on the health factor to ensure that it stays above 1. There's also a risk that the interest rate to borrow UNI climbs above the yield earned on wstETH, resulting in the position being negative yielding. Additionally, smart contract risk is always present.`,
  },
  {
    id: '9',
    title: 'USDC Rate Arbitrage',
    tags: [Tags.leverage4x, Tags.yieldMax, Tags.deltaNeutral],
    collateralAssets: [USDC[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [GRAI[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. USDC and GRAI are both stablecoins, which mitigates risk from price fluctuations. However with the higher leverage of this strategy, extended periods of high borrow rates can push this strategy towards liquidation. Additionally there is always the risk of a depeg.`,
    leverage: 4,
    includeRates: ['ETH Staking APR', 'ETH Staking APY'],
    chain: ChainId.ARBITRUM_ONE,
    supplyOraclePath: ['Chainlink (Market Price)'],
    supplyOracleType: OracleType.chainlink,
    borrowOraclePath: ['Ramses TWAP'],
    borrowOracleType: OracleType.other,
    description: `This strategy utilizes USDC as collateral in order to borrow GRAI, and swaps that GRAI into more USDC collateral, "looping" USDC. This strategy is delta-neutral, as both the collateral and debt are stablecoins. This strategy earns interest in the form of USDC, as well as USDC rewards.`,
    whyItWorks: `This strategy works because the yield on USDC at times is higher than the rate to borrow GRAI when there is high demand to borrow USDC. GRAI and USDC are both stablecoins, so the strategy doesn't have any intrinsic price exposure.`,
    risks: `While USDC earns a higher rate of return when there is high utilization of USDC on Dolomite, that isn't always the case. As a result, if the rate to borrow GRAI remains higher than the yield earned by supplying USDC, this strategy can be negative yielding, and with too much negative yield can be pushed to liquidation. Additionally, risk of depeg and smart contract risk are always present.`,
    disabled: true,
  },
  {
    id: '10',
    title: 'USDC.e Rate Arbitrage',
    tags: [Tags.leverage4x, Tags.yieldMax, Tags.deltaNeutral],
    collateralAssets: [BRIDGED_USDC[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [GRAI[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. USDC.e and GRAI are both stablecoins, which mitigates risk from price fluctuations. However with the higher leverage of this strategy, extended periods of high borrow rates can push this strategy towards liquidation. Additionally there is always the risk of a depeg.`,
    leverage: 4,
    includeRates: ['ETH Staking APR', 'ETH Staking APY'],
    chain: ChainId.ARBITRUM_ONE,
    supplyOraclePath: ['Chainlink (Market Price)'],
    supplyOracleType: OracleType.chainlink,
    borrowOraclePath: ['Ramses TWAP'],
    borrowOracleType: OracleType.other,
    description: `This strategy utilizes USDC.e as collateral in order to borrow GRAI, and swaps that GRAI into more USDC.e collateral, "looping" USDC.e. This strategy is delta-neutral, as both the collateral and debt are stablecoins. This strategy earns interest in the form of USDC.e.`,
    whyItWorks: `This strategy works because the yield on USDC.e at times is higher than the rate to borrow GRAI when there is high demand to borrow USDC.e. GRAI and USDC.e are both stablecoins, so the strategy doesn't have any intrinsic price exposure.`,
    risks: `While USDC.e earns a higher rate of return when there is high utilization of USDC.e on Dolomite, that isn't always the case. As a result, if the rate to borrow GRAI remains higher than the yield earned by supplying USD.eC, this strategy can be negative yielding, and with too much negative yield can be pushed to liquidation. Additionally, risk of depeg and smart contract risk are always present.`,
    disabled: true,
  },
  {
    id: '11',
    title: 'Tether Rate Arbitrage',
    tags: [Tags.leverage4x, Tags.yieldMax, Tags.deltaNeutral],
    collateralAssets: [USDT[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [GRAI[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. USDT and GRAI are both stablecoins, which mitigates risk from price fluctuations. However with the higher leverage of this strategy, extended periods of high borrow rates can push this strategy towards liquidation. Additionally there is always the risk of a depeg.`,
    leverage: 4,
    includeRates: ['ETH Staking APR', 'ETH Staking APY'],
    chain: ChainId.ARBITRUM_ONE,
    supplyOraclePath: ['Chainlink (Market Price)'],
    supplyOracleType: OracleType.chainlink,
    borrowOraclePath: ['Ramses TWAP'],
    borrowOracleType: OracleType.other,
    description: `This strategy utilizes USDT as collateral in order to borrow GRAI, and swaps that GRAI into more USDT collateral, "looping" USDT. This strategy is delta-neutral, as both the collateral and debt are stablecoins. This strategy earns interest in the form of USDT.`,
    whyItWorks: `This strategy works because the yield on USDT at times is higher than the rate to borrow GRAI when there is high demand to borrow USDT. GRAI and USDT are both stablecoins, so the strategy doesn't have any intrinsic price exposure.`,
    risks: `While USDT earns a higher rate of return when there is high utilization of USDT on Dolomite, that isn't always the case. As a result, if the rate to borrow GRAI remains higher than the yield earned by supplying USDT, this strategy can be negative yielding, and with too much negative yield can be pushed to liquidation. Additionally, risk of depeg and smart contract risk are always present.`,
    disabled: true,
  },
  {
    id: '12',
    title: 'Looped gmETH',
    tags: [Tags.leverage5x, Tags.yieldMax, Tags.gmx, Tags.ethBull, Tags.ethExposure1x],
    collateralAssets: [GM_ETH ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. The correlated price of the strategy's debt and collateral helps to reduce risk, however this strategy uses higher leverage. Some risks that do exist are smart contract risk, counterparty risk, as well as the risk that borrow rates on ETH climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 5,
    includeRates: ['GM Fees APR', 'GM Fees APY'],
    chain: ChainId.ARBITRUM_ONE,
    supplyOraclePath: ['Algorithmic + Chainlink (Market Price)'],
    supplyOracleType: OracleType.chainlink,
    borrowOraclePath: ['Chainlink (Market Price)'],
    borrowOracleType: OracleType.chainlink,
    description: `This strategy utilizes gmETH as collateral in order to borrow ETH, and swaps that ETH into more gmETH collateral, "looping" gmETH. This strategy has 1x exposure to the price of ETH, since the debt and looped collateral cancel out leaving only the initial deposit with price exposure. This strategy earns interest in the form of gmETH price appreciation.`,
    whyItWorks: `This strategy works because the yield on gmETH is often higher than the rate to borrow ETH. gmETH approximates the price of ETH. gmETH earns yield from the perpetuals DEX GMX by providing liquidity for trading and leverage.`,
    risks: `GM assets introduce counterparty risk, since the GM pool is the counterparty to traders on GMX. While the design of GMX attempts to minimize counterparty risk, it's possible for traders on GMX to win out at the expense of GM holders. Another risk is that while gmETH generally earns high yield especially at times of high volatility, it doesn't always have high yield. As a result, it's possible that there are periods where ETH has a higher borrow rate and can result in this strategy being negative yielding. An extended period with negative yielding can result in liquidation. Additionally, the risk of smart contract risk is always present.`,
    steps: 2,
  },
  {
    id: '13',
    title: 'Looped gmBTC',
    tags: [Tags.leverage5x, Tags.yieldMax, Tags.gmx, Tags.btcBull],
    collateralAssets: [GM_BTC ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [WBTC[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. The correlated price of the strategy's debt and collateral helps to reduce risk, however this strategy uses higher leverage. Some risks that do exist are smart contract risk, counterparty risk, as well as the risk that borrow rates on WBTC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 5,
    includeRates: ['GM Fees APR', 'GM Fees APY'],
    chain: ChainId.ARBITRUM_ONE,
    supplyOraclePath: ['Algorithmic + Chainlink (Market Price)'],
    supplyOracleType: OracleType.chainlink,
    borrowOraclePath: ['Chainlink (Market Price)'],
    borrowOracleType: OracleType.chainlink,
    description: `This strategy utilizes gmBTC as collateral in order to borrow WBTC, and swaps that WBTC into more gmBTC collateral, "looping" gmBTC. This strategy has 1x exposure to the price of WBTC, since the debt and looped collateral cancel out leaving only the initial deposit with price exposure. This strategy earns interest in the form of gmBTC price appreciation.`,
    whyItWorks: `This strategy works because the yield on gmBTC is often higher than the rate to borrow WBTC. gmBTC approximates the price of WBTC. gmBTC earns yield from the perpetuals DEX GMX by providing liquidity for trading and leverage.`,
    risks: `GM assets introduce counterparty risk, since the GM pool is the counterparty to traders on GMX. While the design of GMX attempts to minimize counterparty risk, it's possible for traders on GMX to win out at the expense of GM holders. Another risk is that while gmBTC generally earns high yield especially at times of high volatility, it doesn't always have high yield. As a result, it's possible that there are periods where WBTC has a higher borrow rate and can result in this strategy being negative yielding. An extended period with negative yielding can result in liquidation. Additionally, the risk of smart contract risk is always present.`,
    steps: 2,
  },
  {
    id: '14',
    title: 'Looped gmUNI-USD',
    tags: [Tags.leverage2x, Tags.yieldMax, Tags.deltaNeutral, Tags.gmx],
    collateralAssets: [GM_UNI_USD ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [UNI[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.low,
    riskAssessment: `This is a low risk strategy. The correlated price of the strategy's debt and collateral helps to reduce risk, and this risk doesn't use much leverage. Some risks that do exist are smart contract risk, counterparty risk, as well as the risk that borrow rates on UNI climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 2,
    includeRates: ['GM Fees APR', 'GM Fees APY'],
    chain: ChainId.ARBITRUM_ONE,
    supplyOraclePath: ['Algorithmic + Chainlink (Market Price)'],
    supplyOracleType: OracleType.chainlink,
    borrowOraclePath: ['Chainlink (Market Price)'],
    borrowOracleType: OracleType.chainlink,
    description: `This strategy utilizes gmUNI-USD as collateral in order to borrow UNI, and swaps that UNI into more gmUNI-USD collateral, "looping" gmUNI-USD. This strategy is delta neutral, since the UNI components of the debt and looped collateral cancel out leaving only USD exposure. This strategy earns interest in the form of gmUNI-USD price appreciation.`,
    whyItWorks: `This strategy works because the yield on gmUNI-USD is often higher than the rate to borrow UNI. gmUNI-USD increases and decreases at 50% the rate of UNI. gmUNI-USD earns yield from the perpetuals DEX GMX by providing liquidity for trading and leverage. The UNI components of the collateral and debt cancel out, leaving the position with no price exposure, but still earning yield.`,
    risks: `GM assets introduce counterparty risk, since the GM pool is the counterparty to traders on GMX. While the design of GMX attempts to minimize counterparty risk, it's possible for traders on GMX to win out at the expense of GM holders. Another risk is that while gmUNI-USD generally earns high yield especially at times of high volatility, it doesn't always have high yield. As a result, it's possible that there are periods where UNI has a higher borrow rate and can result in this strategy being negative yielding. An extended period with negative yielding can result in liquidation. Additionally, the risk of smart contract risk is always present.`,
    steps: 2,
  },
  {
    id: '15',
    title: 'Looped gmLINK-USD',
    tags: [Tags.leverage2x, Tags.yieldMax, Tags.deltaNeutral, Tags.gmx],
    collateralAssets: [GM_LINK_USD ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [LINK[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.low,
    riskAssessment: `This is a low risk strategy. The correlated price of the strategy's debt and collateral helps to reduce risk, and this risk doesn't use much leverage. Some risks that do exist are smart contract risk, counterparty risk, as well as the risk that borrow rates on LINK climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 2,
    includeRates: ['GM Fees APR', 'GM Fees APY'],
    chain: ChainId.ARBITRUM_ONE,
    supplyOraclePath: ['Algorithmic + Chainlink (Market Price)'],
    supplyOracleType: OracleType.chainlink,
    borrowOraclePath: ['Chainlink (Market Price)'],
    borrowOracleType: OracleType.chainlink,
    description: `This strategy utilizes gmLINK-USD as collateral in order to borrow LINK, and swaps that LINK into more gmLINK-USD collateral, "looping" gmLINK-USD. This strategy is delta neutral, since the LINK components of the debt and looped collateral cancel out leaving only USD exposure. This strategy earns interest in the form of gmLINK-USD price appreciation.`,
    whyItWorks: `This strategy works because the yield on gmLINK-USD is often higher than the rate to borrow LINK. gmLINK-USD increases and decreases at 50% the rate of LINK. gmLINK-USD earns yield from the perpetuals DEX GMX by providing liquidity for trading and leverage. The LINK components of the collateral and debt cancel out, leaving the position with no price exposure, but still earning yield.`,
    risks: `GM assets introduce counterparty risk, since the GM pool is the counterparty to traders on GMX. While the design of GMX attempts to minimize counterparty risk, it's possible for traders on GMX to win out at the expense of GM holders. Another risk is that while gmLINK-USD generally earns high yield especially at times of high volatility, it doesn't always have high yield. As a result, it's possible that there are periods where LINK has a higher borrow rate and can result in this strategy being negative yielding. An extended period with negative yielding can result in liquidation. Additionally, the risk of smart contract risk is always present.`,
    steps: 2,
  },
  {
    id: '16',
    title: 'Looped PT-USDe',
    tags: [Tags.leverage5x, Tags.yieldMax, Tags.deltaNeutral, Tags.pendle, Tags.expirable],
    collateralAssets: [PT_USDE ?? WETH[ChainId.MANTLE]],
    debtAssets: [USDT[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. The risk is limited due to the correlated price of the collateral and the debt, however the strategy utilizes high leverage. Some risks that do exist are smart contract risk as well as the risk that borrow rates on USDT climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 5,
    includeRates: ['Pendle Fixed APR', 'ETH Staking APR'],
    chain: ChainId.MANTLE,
    supplyOraclePath: ['PT-USDe', 'USDe - Pendle TWAP', 'USDe', 'USD - Chronicle (Market Price)'],
    supplyOracleType: OracleType.chronicle,
    borrowOraclePath: ['Chronicle (Market Price)'],
    borrowOracleType: OracleType.chronicle,
    description: `This strategy utilizes PT-USDe as collateral in order to borrow USDT, and swaps that USDT into more PT-USDe collateral, "looping" PT-USDe. This strategy is delta neutral because the debt and collateral assets are both stablecoins or stablecoin-pegged. This strategy earns interest in the form of PT-USDe price appreciation.`,
    whyItWorks: `This strategy works because PT-USDe generally earns more interest than is paid on the USDT debt. Additionally, PT-USDe has a stable yield, so you can lock in a high yield. PT-USDe and USDT are also correlated in price, meaning that the value of the debt and collateral will move in the same direction, mitigating price exposure.`,
    risks: `If the borrow rate of USDT climbs above the yield earned on your PT-USDe, the strategy will be negative yielding. If the rate to borrow climbs far above the yield earned for an extended period, there's a chance of liquidation. Additionally, smart contract risk is always present.`,
  },
  {
    id: '17',
    title: 'Looped PT-cmETH',
    tags: [Tags.leverage4x, Tags.yieldMax, Tags.ethExposure1x, Tags.ethStaking, Tags.pendle, Tags.expirable],
    collateralAssets: [PT_CMETH ?? WETH[ChainId.MANTLE]],
    debtAssets: [CM_ETH[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. The risk is limited due to the correlated price of the collateral and the debt, however the strategy utilizes high leverage. Some risks that do exist are smart contract risk as well as the risk that borrow rates on cmETH climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 4,
    includeRates: ['Pendle Fixed APR', 'ETH Staking APR'],
    chain: ChainId.MANTLE,
    supplyOraclePath: ['PT-cmETH', 'cmETH - Pendle TWAP', 'cmETH', 'USD - Chronicle (Market Price)'],
    supplyOracleType: OracleType.chronicle,
    borrowOraclePath: ['Chronicle (Market Price)'],
    borrowOracleType: OracleType.chronicle,
    description: `This strategy utilizes PT-cmETH as collateral in order to borrow cmETH, and swaps that cmETH into more PT-cmETH collateral, "looping" PT-cmETH. This strategy has 1x exposure to the price of cmETH because the debt and collateral assets are correlated in price, so only excess collateral has exposure to price movements. This strategy earns interest in the form of PT-cmETH.`,
    whyItWorks: `This strategy works because PT-cmETH generally earns more interest than is paid on the cmETH debt. Additionally, PT-cmETH has a stable yield, so you can lock in a high yield. PT-cmETH and cmETH are also correlated in price, meaning that the value of the debt and collateral will move in the same direction, mitigating price exposure.`,
    risks: `If the borrow rate of cmETH climbs above the yield earned on your PT-cmETH, the strategy will be negative yielding. If the rate to borrow climbs far above the yield earned for an extended period, there's a chance of liquidation. Additionally, smart contract risk is always present.`,
  },
  {
    id: '18',
    title: 'Looped PT-cmETH',
    tags: [Tags.leverage2x, Tags.yieldMax, Tags.ethExposure1x, Tags.ethStaking, Tags.pendle, Tags.expirable],
    collateralAssets: [PT_CMETH ?? WETH[ChainId.MANTLE]],
    debtAssets: [CM_ETH[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    risk: Risk.medium,
    riskAssessment: `This is a lower risk strategy due to the correlated price of the collateral and the debt. Some risks that do exist are smart contract risk as well as the risk that borrow rates on ETH climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 2,
    includeRates: ['Pendle Fixed APR', 'ETH Staking APR'],
    chain: ChainId.MANTLE,
    supplyOraclePath: ['PT-cmETH', 'cmETH - Pendle TWAP', 'cmETH', 'USD - Chronicle (Market Price)'],
    supplyOracleType: OracleType.chronicle,
    borrowOraclePath: ['Chronicle (Market Price)'],
    borrowOracleType: OracleType.chronicle,
    description: `This strategy utilizes PT-cmETH as collateral in order to borrow cmETH, and swaps that cmETH into more PT-cmETH collateral, "looping" PT-cmETH. This strategy has 1x exposure to the price of cmETH because the debt and collateral assets are correlated in price, so only excess collateral has exposure to price movements. This strategy earns interest in the form of PT-cmETH.`,
    whyItWorks: `This strategy works because PT-cmETH generally earns more interest than is paid on the cmETH debt. Additionally, PT-cmETH has a stable yield, so you can lock in a high yield. PT-cmETH and cmETH are also correlated in price, meaning that the value of the debt and collateral will move in the same direction, mitigating price exposure.`,
    risks: `If the borrow rate of cmETH climbs above the yield earned on your PT-cmETH, the strategy will be negative yielding. If the rate to borrow climbs far above the yield earned for an extended period, there's a chance of liquidation. Additionally, smart contract risk is always present.`,
  },
  {
    id: '19',
    title: 'Looped gmGMX',
    tags: [Tags.leverage3x, Tags.yieldMax, Tags.gmx],
    collateralAssets: [GM_GMX ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [GMX[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. The correlated price of the strategy's debt and collateral helps to reduce risk, however this strategy uses higher leverage. Some risks that do exist are smart contract risk, counterparty risk, as well as the risk that borrow rates on GMX climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 3,
    includeRates: ['GM Fees APR', 'GM Fees APY'],
    chain: ChainId.ARBITRUM_ONE,
    supplyOraclePath: ['Algorithmic + Chainlink (Market Price)'],
    supplyOracleType: OracleType.chainlink,
    borrowOraclePath: ['Chainlink (Market Price)'],
    borrowOracleType: OracleType.chainlink,
    description: `This strategy utilizes gmGMX as collateral in order to borrow GMX, and swaps that GMX into more gmGMX collateral, "looping" gmGMX. This strategy has 1x exposure to the price of GMX, since the debt and looped collateral cancel out leaving only the initial deposit with price exposure. This strategy earns interest in the form of gmGMX price appreciation.`,
    whyItWorks: `This strategy works because the yield on gmGMX is often higher than the rate to borrow GMX. gmGMX approximates the price of GMX. gmGMX earns yield from the perpetuals DEX GMX by providing liquidity for trading and leverage.`,
    risks: `GM assets introduce counterparty risk, since the GM pool is the counterparty to traders on GMX. While the design of GMX attempts to minimize counterparty risk, it's possible for traders on GMX to win out at the expense of GM holders. Another risk is that while gmGMX generally earns high yield especially at times of high volatility, it doesn't always have high yield. As a result, it's possible that there are periods where GMX has a higher borrow rate and can result in this strategy being negative yielding. An extended period with negative yielding can result in liquidation. Additionally, the risk of smart contract risk is always present.`,
    steps: 2,
  },
  {
    id: '20',
    title: 'Looped gmPENDLE-USD',
    tags: [Tags.leverage3x, Tags.yieldMax, Tags.gmx],
    collateralAssets: [GM_PENDLE ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [PENDLE[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.low,
    riskAssessment: `This is a low risk strategy. The correlated price of the strategy's debt and collateral helps to reduce risk, and this risk doesn't use much leverage. Some risks that do exist are smart contract risk, counterparty risk, as well as the risk that borrow rates on PENDLE climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 2,
    includeRates: ['GM Fees APR', 'GM Fees APY'],
    chain: ChainId.ARBITRUM_ONE,
    supplyOraclePath: ['Algorithmic + Chainlink (Market Price)'],
    supplyOracleType: OracleType.chainlink,
    borrowOraclePath: ['Chainlink (Market Price)'],
    borrowOracleType: OracleType.chainlink,
    description: `This strategy utilizes gmPENDLE-USD as collateral in order to borrow PENDLE, and swaps that PENDLE into more gmPENDLE-USD collateral, "looping" gmPENDLE-USD. This strategy is delta neutral, since the PENDLE components of the debt and looped collateral cancel out leaving only USD exposure. This strategy earns interest in the form of gmPENDLE-USD price appreciation.`,
    whyItWorks: `This strategy works because the yield on gmPENDLE-USD is often higher than the rate to borrow PENDLE. gmPENDLE-USD increases and decreases at 50% the rate of PENDLE. gmPENDLE-USD earns yield from the perpetuals DEX GMX by providing liquidity for trading and leverage. The PENDLE components of the collateral and debt cancel out, leaving the position with no price exposure, but still earning yield.`,
    risks: `GM assets introduce counterparty risk, since the GM pool is the counterparty to traders on GMX. While the design of GMX attempts to minimize counterparty risk, it's possible for traders on GMX to win out at the expense of GM holders. Another risk is that while gmPENDLE-USD generally earns high yield especially at times of high volatility, it doesn't always have high yield. As a result, it's possible that there are periods where PENDLE has a higher borrow rate and can result in this strategy being negative yielding. An extended period with negative yielding can result in liquidation. Additionally, the risk of smart contract risk is always present.`,
    steps: 2,
  },
  {
    id: '21',
    title: 'Looped gmETH',
    tags: [Tags.leverage3x, Tags.yieldMax, Tags.gmx, Tags.ethBull, Tags.ethExposure1x],
    collateralAssets: [GM_ETH ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.low,
    riskAssessment: `This is a low risk strategy. The correlated price of the strategy's debt and collateral helps to reduce risk, and this strategy utilizes limited leverage. Some risks that do exist are smart contract risk, counterparty risk, as well as the risk that borrow rates on ETH climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 3,
    includeRates: ['GM Fees APR', 'GM Fees APY'],
    chain: ChainId.ARBITRUM_ONE,
    supplyOraclePath: ['Algorithmic + Chainlink (Market Price)'],
    supplyOracleType: OracleType.chainlink,
    borrowOraclePath: ['Chainlink (Market Price)'],
    borrowOracleType: OracleType.chainlink,
    description: `This strategy utilizes gmETH as collateral in order to borrow ETH, and swaps that ETH into more gmETH collateral, "looping" gmETH. This strategy has 1x exposure to the price of ETH, since the debt and looped collateral cancel out leaving only the initial deposit with price exposure. This strategy earns interest in the form of gmETH price appreciation.`,
    whyItWorks: `This strategy works because the yield on gmETH is often higher than the rate to borrow ETH. gmETH approximates the price of ETH. gmETH earns yield from the perpetuals DEX GMX by providing liquidity for trading and leverage.`,
    risks: `GM assets introduce counterparty risk, since the GM pool is the counterparty to traders on GMX. While the design of GMX attempts to minimize counterparty risk, it's possible for traders on GMX to win out at the expense of GM holders. Another risk is that while gmETH generally earns high yield especially at times of high volatility, it doesn't always have high yield. As a result, it's possible that there are periods where ETH has a higher borrow rate and can result in this strategy being negative yielding. An extended period with negative yielding can result in liquidation. Additionally, the risk of smart contract risk is always present.`,
    steps: 2,
  },
  {
    id: '22',
    title: 'Looped gmBTC',
    tags: [Tags.leverage3x, Tags.yieldMax, Tags.gmx, Tags.btcBull],
    collateralAssets: [GM_BTC ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [WBTC[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.low,
    riskAssessment: `This is a low risk strategy. The correlated price of the strategy's debt and collateral helps to reduce risk, and this strategy utilizes limited leverage. Some risks that do exist are smart contract risk, counterparty risk, as well as the risk that borrow rates on WBTC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 3,
    includeRates: ['GM Fees APR', 'GM Fees APY'],
    chain: ChainId.ARBITRUM_ONE,
    supplyOraclePath: ['Algorithmic + Chainlink (Market Price)'],
    supplyOracleType: OracleType.chainlink,
    borrowOraclePath: ['Chainlink (Market Price)'],
    borrowOracleType: OracleType.chainlink,
    description: `This strategy utilizes gmBTC as collateral in order to borrow WBTC, and swaps that WBTC into more gmBTC collateral, "looping" gmBTC. This strategy has 1x exposure to the price of WBTC, since the debt and looped collateral cancel out leaving only the initial deposit with price exposure. This strategy earns interest in the form of gmBTC price appreciation.`,
    whyItWorks: `This strategy works because the yield on gmBTC is often higher than the rate to borrow WBTC. gmBTC approximates the price of WBTC. gmBTC earns yield from the perpetuals DEX GMX by providing liquidity for trading and leverage.`,
    risks: `GM assets introduce counterparty risk, since the GM pool is the counterparty to traders on GMX. While the design of GMX attempts to minimize counterparty risk, it's possible for traders on GMX to win out at the expense of GM holders. Another risk is that while gmBTC generally earns high yield especially at times of high volatility, it doesn't always have high yield. As a result, it's possible that there are periods where WBTC has a higher borrow rate and can result in this strategy being negative yielding. An extended period with negative yielding can result in liquidation. Additionally, the risk of smart contract risk is always present.`,
    steps: 2,
  },
  {
    id: '23',
    title: 'Looped GLV-ETH',
    tags: [Tags.leverage3x, Tags.yieldMax, Tags.gmx, Tags.deltaNeutral],
    collateralAssets: [GLV_ETH ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.low,
    riskAssessment: `This is a low risk strategy. This strategy uses low leverage, and while not truly delta neutral, the value of the debt and the collateral trend similarly in price. Some risks that do exist are smart contract risk, counterparty risk, the risk that your ETH debt rises sharply in price while the collateral falls in price, as well as the risk that borrow rates on ETH climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 3,
    includeRates: ['GM Fees APR', 'GM Fees APY'],
    chain: ChainId.ARBITRUM_ONE,
    supplyOraclePath: ['Chaos Labs'],
    supplyOracleType: OracleType.other,
    borrowOraclePath: ['Chainlink (Market Price)'],
    borrowOracleType: OracleType.chainlink,
    description: `This strategy utilizes GLV-ETH as collateral in order to borrow ETH, and swaps that ETH into more GLV-ETH collateral, "looping" GLV-ETH. This strategy is not truly delta neutral, but the debt and non-stablecoin portion of the collateral often trend together in price. This strategy earns interest in the form of GLV-ETH price appreciation.`,
    whyItWorks: `This strategy works because the yield on GLV-ETH is often higher than the rate to borrow ETH. The non-stablecoin component of GLV-ETH tends to move in price similarly to ETH. GLV-ETH earns yield from the perpetuals DEX GMX by providing liquidity for trading and leverage.`,
    risks: `GM assets introduce counterparty risk, since the GM pool is the counterparty to traders on GMX. While the design of GMX attempts to minimize counterparty risk, it's possible for traders on GMX to win out at the expense of GM holders. Another risk is that while GLV-ETH generally earns high yield especially at times of high volatility, it doesn't always have high yield. As a result, it's possible that there are periods where ETH has a higher borrow rate and can result in this strategy being negative yielding. An extended period with negative yielding can result in liquidation. Also, if there is a large divergence in the value of GLV-ETH and ETH, it could lead to liquidation. Additionally, the risk of smart contract risk is always present.`,
    steps: 2,
    disabled: true,
  },
  {
    id: '24',
    title: 'Looped GLV-BTC',
    tags: [Tags.leverage3x, Tags.yieldMax, Tags.gmx, Tags.deltaNeutral],
    collateralAssets: [GLV_BTC ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [WBTC[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.low,
    riskAssessment: `This is a low risk strategy. This strategy uses low leverage, and while not truly delta neutral, the value of the debt and the collateral trend similarly in price. Some risks that do exist are smart contract risk, counterparty risk, the risk that your WBTC debt rises sharply in price while the collateral falls in price, as well as the risk that borrow rates on WBTC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 3,
    includeRates: ['GM Fees APR', 'GM Fees APY'],
    chain: ChainId.ARBITRUM_ONE,
    supplyOraclePath: ['Chaos Labs'],
    supplyOracleType: OracleType.other,
    borrowOraclePath: ['Chainlink (Market Price)'],
    borrowOracleType: OracleType.chainlink,
    description: `This strategy utilizes GLV-BTC as collateral in order to borrow WBTC, and swaps that WBTC into more GLV-BTC collateral, "looping" GLV-BTC. This strategy is not truly delta neutral, but the debt and non-stablecoin portion of the collateral often trend together in price. This strategy earns interest in the form of GLV-BTC price appreciation.`,
    whyItWorks: `This strategy works because the yield on GLV-BTC is often higher than the rate to borrow WBTC. The non-stablecoin component of GLV-BTC tends to move in price similarly to WBTC. GLV-BTC earns yield from the perpetuals DEX GMX by providing liquidity for trading and leverage.`,
    risks: `GM assets introduce counterparty risk, since the GM pool is the counterparty to traders on GMX. While the design of GMX attempts to minimize counterparty risk, it's possible for traders on GMX to win out at the expense of GM holders. Another risk is that while GLV-BTC generally earns high yield especially at times of high volatility, it doesn't always have high yield. As a result, it's possible that there are periods where WBTC has a higher borrow rate and can result in this strategy being negative yielding. An extended period with negative yielding can result in liquidation. Also, if there is a large divergence in the value of GLV-BTC and WBTC, it could lead to liquidation. Additionally, the risk of smart contract risk is always present.`,
    steps: 2,
    disabled: true,
  },
  {
    id: '25',
    title: 'Leveraged GLV-ETH',
    tags: [Tags.leverage2x, Tags.yieldMax, Tags.leveragedExposure, Tags.gmx, Tags.ethBull],
    collateralAssets: [GLV_ETH ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [USDC[ChainId.ARBITRUM_ONE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. This strategy utilizes leverage to gain additional price exposure to GLV-ETH, and as a result a drop in the value of the GLV-ETH collateral could lead to a liquidation. This is mitigated by the stablecoin component of GLV and the moderate leverage. Some risks that do exist are smart contract risk, counterparty risk, the risk that your collateral falls in price, as well as the risk that borrow rates on USDC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 2,
    includeRates: ['GM Fees APR', 'GM Fees APY'],
    chain: ChainId.ARBITRUM_ONE,
    supplyOraclePath: ['Chaos Labs'],
    supplyOracleType: OracleType.other,
    borrowOraclePath: ['Chainlink (Market Price)'],
    borrowOracleType: OracleType.chainlink,
    description: `This strategy utilizes GLV-ETH as collateral in order to borrow USDC, and swaps that USDC into more GLV-ETH collateral, "looping" GLV-ETH. This strategy has leveraged exposure to GLV-ETH, which is a basket of assets as well as stablecoins. This strategy earns interest in the form of GLV-ETH price appreciation.`,
    whyItWorks: `This strategy works because the yield on GLV-ETH is often higher than the rate to borrow USDC, and additionally gains value as the basket of assets in GLV-ETH gains value, making it a good strategy for anyone who expects the broader crypto market to trend upwards in value. GLV-ETH earns yield from the perpetuals DEX GMX by providing liquidity for trading and leverage.`,
    risks: `GM assets introduce counterparty risk, since the GM pool is the counterparty to traders on GMX. While the design of GMX attempts to minimize counterparty risk, it's possible for traders on GMX to win out at the expense of GM holders. Also, GLV-ETH can go up or down in value as the market moves, so a drop in the value of GLV-ETH can lead to liquidation. Another risk is that while GLV-ETH generally earns high yield especially at times of high volatility, it doesn't always have high yield. As a result, it's possible that there are periods where USDC has a higher borrow rate and can result in this strategy being negative yielding. An extended period with negative yielding can result in liquidation. Additionally, the risk of smart contract risk is always present.`,
    steps: 2,
    disabled: true,
  },
  {
    id: '26',
    title: 'Leveraged GLV-BTC',
    tags: [Tags.leverage2x, Tags.yieldMax, Tags.leveragedExposure, Tags.gmx, Tags.btcBull],
    collateralAssets: [GLV_BTC ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [USDC[ChainId.ARBITRUM_ONE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. This strategy utilizes leverage to gain additional price exposure to GLV-BTC, and as a result a drop in the value of the GLV-BTC collateral could lead to a liquidation. This is mitigated by the stablecoin component of GLV and the moderate leverage. Some risks that do exist are smart contract risk, counterparty risk, the risk that your collateral falls in price, as well as the risk that borrow rates on USDC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 2,
    includeRates: ['GM Fees APR', 'GM Fees APY'],
    chain: ChainId.ARBITRUM_ONE,
    supplyOraclePath: ['Chaos Labs'],
    supplyOracleType: OracleType.other,
    borrowOraclePath: ['Chainlink (Market Price)'],
    borrowOracleType: OracleType.chainlink,
    description: `This strategy utilizes GLV-BTC as collateral in order to borrow USDC, and swaps that USDC into more GLV-BTC collateral, "looping" GLV-BTC. This strategy has leveraged exposure to GLV-BTC, which is a basket of assets as well as stablecoins. This strategy earns interest in the form of GLV-BTC price appreciation.`,
    whyItWorks: `This strategy works because the yield on GLV-BTC is often higher than the rate to borrow USDC, and additionally gains value as the basket of assets in GLV-BTC gains value, making it a good strategy for anyone who expects the broader crypto market to trend upwards in value. GLV-BTC earns yield from the perpetuals DEX GMX by providing liquidity for trading and leverage.`,
    risks: `GM assets introduce counterparty risk, since the GM pool is the counterparty to traders on GMX. While the design of GMX attempts to minimize counterparty risk, it's possible for traders on GMX to win out at the expense of GM holders. Also, GLV-BTC can go up or down in value as the market moves, so a drop in the value of GLV-BTC can lead to liquidation. Another risk is that while GLV-BTC generally earns high yield especially at times of high volatility, it doesn't always have high yield. As a result, it's possible that there are periods where USDC has a higher borrow rate and can result in this strategy being negative yielding. An extended period with negative yielding can result in liquidation. Additionally, the risk of smart contract risk is always present.`,
    steps: 2,
    disabled: true,
  },
  {
    id: '27',
    title: 'Looped GLV-ETH',
    tags: [Tags.leverage3x, Tags.yieldMax, Tags.gmx, Tags.deltaNeutral],
    collateralAssets: [GLV_ETH ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.low,
    riskAssessment: `This is a low risk strategy. This strategy uses low leverage, and while not truly delta neutral, the value of the debt and the collateral trend similarly in price. Some risks that do exist are smart contract risk, counterparty risk, the risk that your ETH debt rises sharply in price while the collateral falls in price, as well as the risk that borrow rates on ETH climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 2,
    includeRates: ['GM Fees APR', 'GM Fees APY'],
    chain: ChainId.ARBITRUM_ONE,
    supplyOraclePath: ['Chaos Labs'],
    supplyOracleType: OracleType.other,
    borrowOraclePath: ['Chainlink (Market Price)'],
    borrowOracleType: OracleType.chainlink,
    description: `This strategy utilizes GLV-ETH as collateral in order to borrow ETH, and swaps that ETH into more GLV-ETH collateral, "looping" GLV-ETH. This strategy is not truly delta neutral, but the debt and non-stablecoin portion of the collateral often trend together in price. This strategy earns interest in the form of GLV-ETH price appreciation.`,
    whyItWorks: `This strategy works because the yield on GLV-ETH is often higher than the rate to borrow ETH. The non-stablecoin component of GLV-ETH tends to move in price similarly to ETH. GLV-ETH earns yield from the perpetuals DEX GMX by providing liquidity for trading and leverage.`,
    risks: `GM assets introduce counterparty risk, since the GM pool is the counterparty to traders on GMX. While the design of GMX attempts to minimize counterparty risk, it's possible for traders on GMX to win out at the expense of GM holders. Another risk is that while GLV-ETH generally earns high yield especially at times of high volatility, it doesn't always have high yield. As a result, it's possible that there are periods where ETH has a higher borrow rate and can result in this strategy being negative yielding. An extended period with negative yielding can result in liquidation. Also, if there is a large divergence in the value of GLV-ETH and ETH, it could lead to liquidation. Additionally, the risk of smart contract risk is always present.`,
    steps: 2,
  },
  {
    id: '28',
    title: 'Looped GLV-BTC',
    tags: [Tags.leverage3x, Tags.yieldMax, Tags.gmx, Tags.deltaNeutral],
    collateralAssets: [GLV_BTC ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [WBTC[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.low,
    riskAssessment: `This is a low risk strategy. This strategy uses low leverage, and while not truly delta neutral, the value of the debt and the collateral trend similarly in price. Some risks that do exist are smart contract risk, counterparty risk, the risk that your WBTC debt rises sharply in price while the collateral falls in price, as well as the risk that borrow rates on WBTC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 2,
    includeRates: ['GM Fees APR', 'GM Fees APY'],
    chain: ChainId.ARBITRUM_ONE,
    supplyOraclePath: ['Chaos Labs'],
    supplyOracleType: OracleType.other,
    borrowOraclePath: ['Chainlink (Market Price)'],
    borrowOracleType: OracleType.chainlink,
    description: `This strategy utilizes GLV-BTC as collateral in order to borrow WBTC, and swaps that WBTC into more GLV-BTC collateral, "looping" GLV-BTC. This strategy is not truly delta neutral, but the debt and non-stablecoin portion of the collateral often trend together in price. This strategy earns interest in the form of GLV-BTC price appreciation.`,
    whyItWorks: `This strategy works because the yield on GLV-BTC is often higher than the rate to borrow WBTC. The non-stablecoin component of GLV-BTC tends to move in price similarly to WBTC. GLV-BTC earns yield from the perpetuals DEX GMX by providing liquidity for trading and leverage.`,
    risks: `GM assets introduce counterparty risk, since the GM pool is the counterparty to traders on GMX. While the design of GMX attempts to minimize counterparty risk, it's possible for traders on GMX to win out at the expense of GM holders. Another risk is that while GLV-BTC generally earns high yield especially at times of high volatility, it doesn't always have high yield. As a result, it's possible that there are periods where WBTC has a higher borrow rate and can result in this strategy being negative yielding. An extended period with negative yielding can result in liquidation. Also, if there is a large divergence in the value of GLV-BTC and WBTC, it could lead to liquidation. Additionally, the risk of smart contract risk is always present.`,
    steps: 2,
  },
  {
    id: '29',
    title: 'Leveraged GLV-ETH',
    tags: [Tags.leverage2x, Tags.yieldMax, Tags.leveragedExposure, Tags.gmx, Tags.ethBull],
    collateralAssets: [GLV_ETH ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [USDC[ChainId.ARBITRUM_ONE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. This strategy utilizes leverage to gain additional price exposure to GLV-ETH, and as a result a drop in the value of the GLV-ETH collateral could lead to a liquidation. This is mitigated by the stablecoin component of GLV and the moderate leverage. Some risks that do exist are smart contract risk, counterparty risk, the risk that your collateral falls in price, as well as the risk that borrow rates on USDC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 3,
    includeRates: ['GM Fees APR', 'GM Fees APY'],
    chain: ChainId.ARBITRUM_ONE,
    supplyOraclePath: ['Chaos Labs'],
    supplyOracleType: OracleType.other,
    borrowOraclePath: ['Chainlink (Market Price)'],
    borrowOracleType: OracleType.chainlink,
    description: `This strategy utilizes GLV-ETH as collateral in order to borrow USDC, and swaps that USDC into more GLV-ETH collateral, "looping" GLV-ETH. This strategy has leveraged exposure to GLV-ETH, which is a basket of assets as well as stablecoins. This strategy earns interest in the form of GLV-ETH price appreciation.`,
    whyItWorks: `This strategy works because the yield on GLV-ETH is often higher than the rate to borrow USDC, and additionally gains value as the basket of assets in GLV-ETH gains value, making it a good strategy for anyone who expects the broader crypto market to trend upwards in value. GLV-ETH earns yield from the perpetuals DEX GMX by providing liquidity for trading and leverage.`,
    risks: `GM assets introduce counterparty risk, since the GM pool is the counterparty to traders on GMX. While the design of GMX attempts to minimize counterparty risk, it's possible for traders on GMX to win out at the expense of GM holders. Also, GLV-ETH can go up or down in value as the market moves, so a drop in the value of GLV-ETH can lead to liquidation. Another risk is that while GLV-ETH generally earns high yield especially at times of high volatility, it doesn't always have high yield. As a result, it's possible that there are periods where USDC has a higher borrow rate and can result in this strategy being negative yielding. An extended period with negative yielding can result in liquidation. Additionally, the risk of smart contract risk is always present.`,
    steps: 2,
  },
  {
    id: '30',
    title: 'Leveraged GLV-BTC',
    tags: [Tags.leverage2x, Tags.yieldMax, Tags.leveragedExposure, Tags.gmx, Tags.btcBull],
    collateralAssets: [GLV_BTC ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [USDC[ChainId.ARBITRUM_ONE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. This strategy utilizes leverage to gain additional price exposure to GLV-BTC, and as a result a drop in the value of the GLV-BTC collateral could lead to a liquidation. This is mitigated by the stablecoin component of GLV and the moderate leverage. Some risks that do exist are smart contract risk, counterparty risk, the risk that your collateral falls in price, as well as the risk that borrow rates on USDC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 3,
    includeRates: ['GM Fees APR', 'GM Fees APY'],
    chain: ChainId.ARBITRUM_ONE,
    supplyOraclePath: ['Chaos Labs'],
    supplyOracleType: OracleType.other,
    borrowOraclePath: ['Chainlink (Market Price)'],
    borrowOracleType: OracleType.chainlink,
    description: `This strategy utilizes GLV-BTC as collateral in order to borrow USDC, and swaps that USDC into more GLV-BTC collateral, "looping" GLV-BTC. This strategy has leveraged exposure to GLV-BTC, which is a basket of assets as well as stablecoins. This strategy earns interest in the form of GLV-BTC price appreciation.`,
    whyItWorks: `This strategy works because the yield on GLV-BTC is often higher than the rate to borrow USDC, and additionally gains value as the basket of assets in GLV-BTC gains value, making it a good strategy for anyone who expects the broader crypto market to trend upwards in value. GLV-BTC earns yield from the perpetuals DEX GMX by providing liquidity for trading and leverage.`,
    risks: `GM assets introduce counterparty risk, since the GM pool is the counterparty to traders on GMX. While the design of GMX attempts to minimize counterparty risk, it's possible for traders on GMX to win out at the expense of GM holders. Also, GLV-BTC can go up or down in value as the market moves, so a drop in the value of GLV-BTC can lead to liquidation. Another risk is that while GLV-BTC generally earns high yield especially at times of high volatility, it doesn't always have high yield. As a result, it's possible that there are periods where USDC has a higher borrow rate and can result in this strategy being negative yielding. An extended period with negative yielding can result in liquidation. Additionally, the risk of smart contract risk is always present.`,
    steps: 2,
  },
  {
    id: '31',
    title: 'Looped sUSDe',
    tags: [Tags.leverage3x, Tags.yieldMax, Tags.rewardsMax, Tags.deltaNeutral, Tags.sats],
    collateralAssets: [SUSDE[ChainId.BERACHAIN] ?? USDC[ChainId.BERACHAIN]],
    debtAssets: [USDC[ChainId.BERACHAIN]],
    risk: Risk.low,
    riskAssessment: `This is a lower risk strategy due to the correlated price of the debt and collateral. Some risks that do exist are smart contract risk as well as the risk that borrow rates on USDC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 3,
    chain: ChainId.BERACHAIN,
    includeRates: ['Staking yield'],
    supplyOraclePath: ['sUSDe', 'USDe - Redstone (Exchange Rate)', 'USD - Redstone (Market Price)'],
    supplyOracleType: OracleType.redstone,
    borrowOraclePath: ['Chronicle (Market Price)'],
    borrowOracleType: OracleType.chronicle,
    description: `This strategy utilizes sUSDe as collateral in order to borrow USDC, and swaps that USDC into more sUSDe collateral, "looping" sUSDe. This strategy is delta-neutral, as both the collateral and debt are stablecoins. This strategy earns interest in the form of sUSDe price appreciation, as well as Ethena Sats.`,
    whyItWorks: `This strategy works because the yield on sUSDe is generally higher than the rate to borrow USDC. sUSDe and USDC are both stablecoins, so the strategy doesn't have any intrinsic price exposure.`,
    risks: `If the borrow rate of USDC climbs above the yield earned on your sUSDe, the strategy will be negative yielding. If the rate to borrow climbs far above the yield earned for an extended period, there's a chance of liquidation. Additionally, smart contract risk is always present.`,
  },
  {
    id: '32',
    title: 'Looped sUSDe',
    tags: [Tags.leverage3x, Tags.yieldMax, Tags.rewardsMax, Tags.deltaNeutral, Tags.sats],
    collateralAssets: [SUSDE[ChainId.BERACHAIN] ?? USDC[ChainId.BERACHAIN]],
    debtAssets: [USDT[ChainId.BERACHAIN] ?? USDC[ChainId.BERACHAIN]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy, as despite the correlated price of the debt and collateral, the leverage is quite high. Some risks that do exist are smart contract risk as well as the risk that borrow rates on USDT climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 3,
    chain: ChainId.BERACHAIN,
    includeRates: ['Staking yield'],
    supplyOraclePath: ['sUSDe', 'USDe - Redstone (Exchange Rate)', 'USD - Redstone (Market Price)'],
    supplyOracleType: OracleType.redstone,
    borrowOraclePath: ['Chronicle (Market Price)'],
    borrowOracleType: OracleType.chronicle,
    description: `This strategy utilizes sUSDe as collateral in order to borrow USDT, and swaps that USDT into more sUSDe collateral, "looping" sUSDe. This strategy is delta-neutral, as both the collateral and debt are stablecoins. This strategy earns interest in the form of sUSDe price appreciation, as well as Ethena Sats.`,
    whyItWorks: `This strategy works because the yield on sUSDe is generally higher than the rate to borrow USDT. sUSDe and USDT are both stablecoins, so the strategy doesn't have any intrinsic price exposure.`,
    risks: `If the borrow rate of USDT climbs above the yield earned on your sUSDe, the strategy will be negative yielding. If the rate to borrow climbs far above the yield earned for an extended period, there's a chance of liquidation. Additionally, smart contract risk is always present.`,
  },
  {
    id: '33',
    title: 'Looped sUSDe',
    tags: [Tags.leverage3x, Tags.yieldMax, Tags.rewardsMax, Tags.deltaNeutral, Tags.sats],
    collateralAssets: [SUSDE[ChainId.BERACHAIN] ?? USDC[ChainId.BERACHAIN]],
    debtAssets: [HONEY[ChainId.BERACHAIN] ?? USDC[ChainId.BERACHAIN]],
    risk: Risk.low,
    riskAssessment: `This is a lower risk strategy due to the correlated price of the debt and collateral. Some risks that do exist are smart contract risk as well as the risk that borrow rates on HONEY climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 3,
    chain: ChainId.BERACHAIN,
    includeRates: ['Staking yield'],
    supplyOraclePath: ['sUSDe', 'USDe - Redstone (Exchange Rate)', 'USD - Redstone (Market Price)'],
    supplyOracleType: OracleType.redstone,
    borrowOraclePath: ['Chronicle (Market Price)'],
    borrowOracleType: OracleType.chronicle,
    description: `This strategy utilizes sUSDe as collateral in order to borrow HONEY, and swaps that HONEY into more sUSDe collateral, "looping" sUSDe. This strategy is delta-neutral, as both the collateral and debt are stablecoins. This strategy earns interest in the form of sUSDe price appreciation, as well as Ethena Sats.`,
    whyItWorks: `This strategy works because the yield on sUSDe is generally higher than the rate to borrow HONEY. sUSDe and HONEY are both stablecoins, so the strategy doesn't have any intrinsic price exposure.`,
    risks: `If the borrow rate of HONEY climbs above the yield earned on your sUSDe, the strategy will be negative yielding. If the rate to borrow climbs far above the yield earned for an extended period, there's a chance of liquidation. Additionally, smart contract risk is always present.`,
  },
  {
    id: '34',
    title: 'Looped srUSD',
    tags: [Tags.leverage7x, Tags.yieldMax, Tags.deltaNeutral, Tags.sats],
    specialTag: 'Featured',
    collateralAssets: [SR_USD[ChainId.BERACHAIN] ?? USDC[ChainId.BERACHAIN]],
    debtAssets: [USDC[ChainId.BERACHAIN] ?? USDT[ChainId.BERACHAIN]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy - made less risky due to the correlated price of the debt and collateral, but more risky due to the high leverage. Some risks that do exist are smart contract risk as well as the risk that borrow rates on USDC climb high for an extended period and cause the value of your debt to grow to the point of liquidation. There is also the risk of losing peg.`,
    leverage: 7,
    chain: ChainId.BERACHAIN,
    includeRates: ['Staking yield'],
    supplyOraclePath: ['srUSD', 'rUSD - Chronicle (Exchange Rate)', 'rUSD', 'USD - Chronicle (Market Price)'],
    supplyOracleType: OracleType.chronicle,
    borrowOraclePath: ['Chronicle (Market Price)'],
    borrowOracleType: OracleType.chronicle,
    description: `This strategy utilizes srUSD as collateral in order to borrow USDC, and swaps that USDC into more srUSD collateral, "looping" srUSD. This strategy is delta-neutral, as both the collateral and debt are USD-correlated. This strategy earns interest in the form of srUSD price appreciation.`,
    whyItWorks: `This strategy works because the yield on srUSD is generally higher than the rate to borrow USDC. srUSD and USDC are both USD-correlated in price, so the strategy doesn't have any intrinsic price exposure.`,
    risks: `If the borrow rate of USDC climbs above the yield earned on your srUSD, the strategy will be negative yielding. If the rate to borrow climbs far above the yield earned for an extended period, there is a chance of liquidation. If srUSD loses its correlation in price with USDC, or vice versa, that can also risk liquidation. Additionally, smart contract risk is always present.`,
  },
  {
    id: '35',
    title: 'Looped beraETH',
    tags: [Tags.leverage7x, Tags.yieldMax, Tags.ethStaking, Tags.deltaNeutral, Tags.ethExposure1x, Tags.ethBull],
    specialTag: 'Featured',
    collateralAssets: [BERA_ETH[ChainId.BERACHAIN] ?? USDC[ChainId.BERACHAIN]],
    debtAssets: [WETH[ChainId.BERACHAIN] ?? USDT[ChainId.BERACHAIN]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy - made less risky due to the correlated price of the debt and collateral, but more risky due to the high leverage. Some risks that do exist are smart contract risk as well as the risk that borrow rates on ETH climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 8,
    chain: ChainId.BERACHAIN,
    includeRates: ['Staking yield'],
    supplyOraclePath: ['beraETH', 'ETH - Redstone (Exchange Rate)', 'ETH', 'USD - Redstone (Market Price)'],
    supplyOracleType: OracleType.redstone,
    borrowOraclePath: ['Chronicle (Market Price)'],
    borrowOracleType: OracleType.chronicle,
    description: `This strategy utilizes beraETH as collateral in order to borrow ETH, and swaps that ETH into more beraETH collateral, "looping" beraETH. This strategy is delta-neutral, as both the collateral and debt are ETH-correlated. This strategy earns interest in the form of beraETH price appreciation.`,
    whyItWorks: `This strategy works because the yield on beraETH is generally higher than the rate to borrow ETH. beraETH and ETH are both ETH-correlated in price, so the strategy doesn't have any intrinsic price exposure.`,
    risks: `If the borrow rate of ETH climbs above the yield earned on your beraETH, the strategy will be negative yielding. If the rate to borrow climbs far above the yield earned for an extended period, there's a chance of liquidation, especially given the high leverage of the strategy. Additionally, smart contract risk is always present.`,
    disabled: true,
  },
  {
    id: '36',
    title: 'Looped beraETH',
    tags: [Tags.leverage8x, Tags.yieldMax, Tags.ethStaking, Tags.deltaNeutral, Tags.ethExposure1x, Tags.ethBull],
    collateralAssets: [BERA_ETH[ChainId.BERACHAIN] ?? USDC[ChainId.BERACHAIN]],
    debtAssets: [WETH[ChainId.BERACHAIN] ?? USDT[ChainId.BERACHAIN]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy - made less risky due to the correlated price of the debt and collateral, but more risky due to the high leverage. Some risks that do exist are smart contract risk as well as the risk that borrow rates on ETH climb high for an extended period and cause the value of your debt to grow to the point of liquidation.`,
    leverage: 7,
    chain: ChainId.BERACHAIN,
    includeRates: ['Staking yield'],
    supplyOraclePath: ['beraETH', 'ETH - Redstone (Exchange Rate)', 'ETH', 'USD - Redstone (Market Price)'],
    supplyOracleType: OracleType.redstone,
    borrowOraclePath: ['Chronicle (Market Price)'],
    borrowOracleType: OracleType.chronicle,
    description: `This strategy utilizes beraETH as collateral in order to borrow ETH, and swaps that ETH into more beraETH collateral, "looping" beraETH. This strategy is delta-neutral, as both the collateral and debt are ETH-correlated. This strategy earns interest in the form of beraETH price appreciation.`,
    whyItWorks: `This strategy works because the yield on beraETH is generally higher than the rate to borrow ETH. beraETH and ETH are both ETH-correlated in price, so the strategy retains only approximately 1x ETH price exposure.`,
    risks: `If the borrow rate of ETH climbs above the yield earned on your beraETH, the strategy will be negative yielding. If the rate to borrow climbs far above the yield earned for an extended period, there is a chance of liquidation, especially given the high leverage of the strategy. Additionally, smart contract risk is always present.`,
  },
  {
    id: '37',
    title: 'Looped sUSDe',
    tags: [Tags.leverage8x, Tags.yieldMax, Tags.rewardsMax, Tags.deltaNeutral, Tags.sats],
    collateralAssets: [SUSDE[ChainId.BERACHAIN] ?? USDC[ChainId.BERACHAIN]],
    debtAssets: [USDE[ChainId.BERACHAIN] ?? USDC[ChainId.BERACHAIN]],
    risk: Risk.low,
    riskAssessment: `This is a lower risk strategy due to the high correlation between the debt and collateral, as a result of utilizing the same price feed. Some risks that do exist are smart contract risk as well as the risk that borrow rates on USDe climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 8,
    chain: ChainId.BERACHAIN,
    includeRates: ['Staking yield'],
    supplyOraclePath: ['sUSDe', 'USDe - Redstone (Exchange Rate)', 'USDe', 'USD - Redstone (Market Price)'],
    supplyOracleType: OracleType.redstone,
    borrowOraclePath: ['Redstone (Market Price)'],
    borrowOracleType: OracleType.redstone,
    description: `This strategy utilizes sUSDe as collateral in order to borrow USDe, and swaps that USDe into more sUSDe collateral, "looping" sUSDe. This strategy is delta-neutral, as both the collateral and debt are stablecoins and directly correlated in price. This strategy earns interest in the form of sUSDe price appreciation, as well as Ethena Sats.`,
    whyItWorks: `This strategy works because the yield on sUSDe is generally higher than the rate to borrow USDe. sUSDe and USDe are both stablecoins and directly correlated in price, so the strategy doesn't have any intrinsic price exposure.`,
    risks: `If the borrow rate of USDe climbs above the yield earned on your sUSDe, the strategy will be negative yielding. If the rate to borrow climbs far above the yield earned for an extended period, there's a chance of liquidation. Additionally, smart contract risk is always present.`,
  },
  {
    id: '38',
    title: 'Looped weETH',
    tags: [
      Tags.leverage7x,
      Tags.yieldMax,
      Tags.ethStaking,
      Tags.lrt,
      Tags.deltaNeutral,
      Tags.ethExposure1x,
      Tags.ethBull,
    ],
    collateralAssets: [WE_ETH[ChainId.BERACHAIN] ?? USDC[ChainId.BERACHAIN]],
    debtAssets: [WETH[ChainId.BERACHAIN] ?? USDT[ChainId.BERACHAIN]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy - made less risky due to the correlated price of the debt and collateral, but more risky due to the high leverage. Some risks that do exist are smart contract risk as well as the risk that borrow rates on ETH climb high for an extended period and cause the value of your debt to grow to the point of liquidation.`,
    leverage: 7,
    chain: ChainId.BERACHAIN,
    includeRates: ['ETH Staking APR', 'ETH Staking APY', 'ETH Restaking APR', 'ETH Restaking APY'],
    supplyOraclePath: ['weETH', 'ETH - Redstone (Market Price)', 'ETH', 'USD - Redstone (Market Price)'],
    supplyOracleType: OracleType.redstone,
    borrowOraclePath: ['Chronicle (Market Price)'],
    borrowOracleType: OracleType.chronicle,
    description: `This strategy utilizes weETH as collateral in order to borrow ETH, and swaps that ETH into more weETH collateral, "looping" weETH. This strategy is delta-neutral, as both the collateral and debt are ETH-correlated. This strategy earns interest in the form of weETH price appreciation.`,
    whyItWorks: `This strategy works because the yield on weETH is generally higher than the rate to borrow ETH. weETH and ETH are both ETH-correlated in price, so the strategy retains only approximately 1x ETH price exposure.`,
    risks: `If the borrow rate of ETH climbs above the yield earned on your weETH, the strategy will be negative yielding. If the rate to borrow climbs far above the yield earned for an extended period, there is a chance of liquidation, especially given the high leverage of the strategy. Additionally, smart contract risk is always present.`,
  },
]

export function useStrategyAmounts(
  strategy: StrategyInfo | undefined,
): [CurrencyAmount<Token>[], CurrencyAmount<Token>[]] {
  const leverage = strategy?.leverage ?? 1
  const [fiatPrices] = useFiatPricesWithLoadingIndicator(undefined, strategy?.chain, RefreshFrequency.Medium)
  const supplyAmounts = useMemo(
    () =>
      strategy?.collateralAssets.map(token => {
        const price = fiatPrices[token.address ?? '']
        const parsedAmountWithLeverage = tryParseAmount(leverage.toString(), token)
        return (
          parsedAmountWithLeverage?.divide(price?.greaterThan(0) ? price : ONE_FRACTION) ??
          CurrencyAmount.fromRawAmount(token, '0')
        )
      }),
    [fiatPrices, leverage, strategy?.collateralAssets],
  )
  const borrowAmounts = useMemo(
    () =>
      strategy?.debtAssets.map(token => {
        const price = fiatPrices[token.address ?? '']
        const parsedAmountWithLeverage = tryParseAmount((leverage - 1).toString(), token)
        return (
          parsedAmountWithLeverage?.divide(price?.greaterThan(0) ? price : ONE_FRACTION) ??
          CurrencyAmount.fromRawAmount(token, '0')
        )
      }),
    [fiatPrices, leverage, strategy?.debtAssets],
  )
  return useMemo(() => [supplyAmounts ?? [], borrowAmounts ?? []], [supplyAmounts, borrowAmounts])
}

export function useStrategiesAmounts(): [CurrencyAmount<Token>[][], CurrencyAmount<Token>[][]] {
  const { data: chainIdToTokenToFiatPriceMap } = useChainIdToTokenToFiatPriceMap(RefreshFrequency.Medium)
  const supplyAmounts = useMemo(
    () =>
      STRATEGIES_DATA.map(strategy =>
        strategy.collateralAssets.map(token => {
          const price = chainIdToTokenToFiatPriceMap[token.chainId as ChainId][token.address ?? '']
          const parsedAmountWithLeverage = tryParseAmount(strategy.leverage.toString(), token)
          return (
            parsedAmountWithLeverage?.divide(price?.greaterThan(0) ? price : ONE_FRACTION) ??
            CurrencyAmount.fromRawAmount(token, '0')
          )
        }),
      ),
    [chainIdToTokenToFiatPriceMap],
  )
  const borrowAmounts = useMemo(
    () =>
      STRATEGIES_DATA.map(strategy =>
        strategy.debtAssets.map(token => {
          const price = chainIdToTokenToFiatPriceMap[token.chainId as ChainId][token.address ?? '']
          const parsedAmountWithLeverage = tryParseAmount((strategy.leverage - 1).toString(), token)
          return (
            parsedAmountWithLeverage?.divide(price?.greaterThan(0) ? price : ONE_FRACTION) ??
            CurrencyAmount.fromRawAmount(token, '0')
          )
        }),
      ),
    [chainIdToTokenToFiatPriceMap],
  )
  return useMemo(() => [supplyAmounts, borrowAmounts], [supplyAmounts, borrowAmounts])
}

export function useStrategyData(): StrategyWithAmounts[] {
  const amountsForEachStrategy = useStrategiesAmounts()
  return useMemo(() => {
    return STRATEGIES_DATA.map((strategy, i) => {
      return {
        ...strategy,
        supplyAmounts: amountsForEachStrategy[0][i],
        borrowAmounts: amountsForEachStrategy[1][i],
      }
    })
  }, [amountsForEachStrategy])
}

function getStrategyInterestRateData(
  supplyAmounts: CurrencyAmount<Token>[],
  borrowAmounts: CurrencyAmount<Token>[],
  tokenToPriceMap: Record<string, Fraction | undefined>,
  tokenToInterestRateMap: Record<string, InterestRate | undefined>,
  includeRates: string[] | undefined,
): [Percent | undefined, Percent | undefined] {
  const totalRate = getStrategyInterestRate(supplyAmounts, borrowAmounts, tokenToPriceMap, tokenToInterestRateMap, true)
  const interestRate = getStrategyInterestRate(
    supplyAmounts,
    borrowAmounts,
    tokenToPriceMap,
    tokenToInterestRateMap,
    false,
    false,
    includeRates,
  )
  return [totalRate, interestRate]
}

export function useStrategyInterestRateMap(): Record<string, (Percent | undefined)[]> {
  const { data: chainToTokenToPricesMap } = useChainIdToTokenToFiatPriceMap()
  const { data: chainToTokenToInterestRateMap } = useChainIdToInterestRateMap()
  const amountsForEachStrategy = useStrategiesAmounts()

  return useMemo(() => {
    return STRATEGIES_DATA.map((t, i) => {
      const supplyAmounts = amountsForEachStrategy[0][i]
      const borrowAmounts = amountsForEachStrategy[1][i]
      return getStrategyInterestRateData(
        supplyAmounts,
        borrowAmounts,
        chainToTokenToPricesMap[t.chain],
        chainToTokenToInterestRateMap[t.chain],
        t.includeRates,
      )
    }).reduce((memo, calculatedData, i) => {
      memo[STRATEGIES_DATA[i].id] = calculatedData
      return memo
    }, {} as Record<string, (Percent | undefined)[]>)
  }, [amountsForEachStrategy, chainToTokenToInterestRateMap, chainToTokenToPricesMap])
}

export function getIndexOfPositionStrategy(position: BorrowPosition, strategies: StrategyWithAmounts[]) {
  const positionId = parseFloat(position?.id.split('-')[1])
  if (positionId >= STRATEGY_INDEX_LOWER && positionId <= STRATEGY_INDEX_UPPER) {
    const foundStrategy = strategies?.reduce((memo, strategy) => {
      memo[strategy.id] = false
      const matchingStrategy = strategy.supplyAmounts.map((supplyAmount, index) => {
        const positionSupply = position.supplyAmounts[index]
        const positionBorrow = position.borrowAmounts[index]
        if (!positionSupply || !positionBorrow) {
          return false
        }
        const sameToken =
          supplyAmount.currency.address.toUpperCase() === positionSupply.token.address.toUpperCase() &&
          strategy.borrowAmounts[index].currency.address.toUpperCase() === positionBorrow.token.address.toUpperCase()
        if (!sameToken) return false
        const positionRatio = positionSupply.amountTokenWei.divide(positionBorrow.amountTokenWei)
        const strategyRatio = supplyAmount.divide(strategy.borrowAmounts[index])
        return formatAmount(positionRatio) === formatAmount(strategyRatio)
      })
      if (matchingStrategy.includes(true)) memo[strategy.id] = true
      return memo
    }, {} as Record<string, boolean>)
    const index = Object.values(foundStrategy).indexOf(true)
    return index > -1 ? index : false
  }
  return false
}

export default STRATEGIES_DATA
